@use '@angular/material' as mat;
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Sharp';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');

$fontConfig: (
  display-4:
    mat.define-typography-level(112px, 112px, 400, 'Manrope', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Manrope', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Manrope', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Manrope', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 800, 'Manrope', 0em),
  title: mat.define-typography-level(20px, 32px, 800, 'Manrope', 0.0075em),
  subheading-2:
    mat.define-typography-level(16px, 28px, 800, 'Manrope', 0.0094em),
  subheading-1:
    mat.define-typography-level(15px, 24px, 800, 'Manrope', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 800, 'Manrope', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Manrope', 0.0179em),
  button: mat.define-typography-level(14px, 12px, 800, 'Manrope', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Manrope', 0.0333em),
  input: mat.define-typography-level(16px, 1.25, 400, 'Manrope', normal),
);

$dark-text: #1e2b4e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

@include mat.core($fontConfig);

body {
  --primary-color: #228cdb;
  --primary-lighter-color: #1a6ba8;
  --primary-darker-color: #1e2b4e;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$light-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #1e2b4e,
  lighter: #1a6ba8,
  darker: #1e2b4e,
  200: #228cdb,
  contrast: (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  ),
);

.primaryColorBG {
  background-color: map-get($mat-primary, darker) !important;
}

.primaryColor {
  color: map-get($mat-primary, darker);
}

.primaryColor-border {
  border-color: map-get($mat-primary, darker) !important;
}

.mat-tooltip {
  font-size: 12px;
}

.mat-tab-labels {
  background: #f7f8fa;

  font-size: 18px;

  .mat-tab-label {
    color: map-get($mat-primary, darker);
  }
  .mat-tab-label-active {
    opacity: 1;
  }
}

.mat-form-field-infix {
  font-size: 14px;
}

.mat-form-field-outline {
  background-color: #f7f8fa;
  color: rgba(0, 0, 0, 0.05) !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.5em;
}

.mat-stroked-button,
.mat-flat-button {
  padding: 16px 0;
  font-weight: 800;

  &:disabled {
    mat-icon {
      filter: opacity(0.5);
    }
  }
}

.mat-stroked-button {
  border-color: map-get($mat-primary, darker);
}

mat-icon {
  font-weight: 400;

  &.visible {
    overflow: visible;
  }
}

.right .mat-sort-header-container {
  direction: rtl;
}

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #63e776;
  --accent-lighter-color: #a1f1ad;
  --accent-darker-color: #378142;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #63e776,
  lighter: #a1f1ad,
  darker: #378142,
  200: #63e776,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$pink-accent: (
  main: #ffb3c3,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

.secondaryColor {
  color: map-get($mat-accent, main);
}

.txpPink {
  color: map-get($pink-accent, main);
}

.secondaryColorBG {
  background-color: map-get($mat-accent, main);
}

$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

@include mat.all-component-themes($theme);
