@import 'techspert-theme.scss';

@import 'ngx-toastr/toastr';

html {
  font-family: 'Manrope';
}

body {
  margin: 0;
  color: #1e2b4e;
}

* {
  box-sizing: border-box;
}

:root {
  --font-color: #1e2b4e;
  --spacing-m: 1.25rem; //20px equiv
}

.custom-dialog-container .mat-dialog-container {
  overflow: visible;
}

.omnisearch-item {
  .omnisearch-result-tag {
    color: #1E2B4E;
    font-weight: 800;
  }
}
.mat-group-custom-style {
  .mat-tab-label-active {
    font-weight: 600;
    color: black;
  }
  .mat-tab-label-active mat-icon {
    color: #228cdb;
  }
  mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #228cdb;
  }

  .mat-tab-disabled {
    background-color: #e2e0e0d9;
    border-radius: 8px 8px 0 0;
  }
}

#toast-container {
  font-size: 16px;
}
